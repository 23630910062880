@media only screen and (max-width: 992px) {

    #rocket{
        margin-left:0;
        display: none !important;
    }

    .videoTag{
        width:400px;
        height:400px;
        bottom:100px;
    }

    #resumeButtons{
        top:-200px;
    }

    .nav-mobile{
        display:block;
    }
    #resumeNavbar {
        left:unset;
        width: 100%;
    }

   .ability-box{
    text-align: center;
   }
    
    #GoToProfileButton{
        width:100%;
        left:0px;
    }

    #Abilities h2{
        text-align: center;
    }

    .navbar-nav{
        display:block;
    }

    .bg-dark{
        background-color: black !important;
    }

    #Dark{
        margin-top:0;
        width:100%;
        position: fixed;
        max-width: 100vw;
        z-index: 99;
        height: fit-content;
    }

    .nonSticky {
        position: inherit;
    }

    .sticky{
       top: 0px;
    }

    #resumeNavbar{
        color:white;
        background-color: black !important;
      }

      #resumeNavbar > div{
          width:100%;
      }

     

      #Profile{
          height: 1400px;
      }
      #profileImage{
          width: 100%;
      }
      #AboutMeDiv{
          width:100%;
          margin-left:0px;
          margin-top:30px;
      }

      .experience-div{
          margin-left:0px;
          margin-right:0px;
      }
      .education-section{
          width:100%;
          text-align: center;
      }

      .education-name{
          width:100%;
      }

      .education-qualififcation{
          width:100%;
          text-align: center;
          margin-left:0px;
      }
      .mobile-title{
          width: 100%;
          text-align: center;
      }

      #Experiences{
          height:fit-content;
      }

      #Abilities{
          height:fit-content;
      }

      #Projects{
          height:fit-content;
      }

      .contact-details{
          width:100%;
          margin-bottom:0px;
      }
      .contact-form{
          width:100%;
          margin-bottom:50px;
      }
    }

    @media only screen and (max-width: 700px) {
        #Experiences{
            height:fit-content;
        } 
    }

    @media only screen and (max-width: 413px) {
        #Experiences{
            height:fit-content;
        }
        #Profile{
            height:fit-content;
        }
    }

    @media only screen and (max-width: 530px) {
        #Experiences{
            height:fit-content;
        }
        .videoTag{
            width:300px;
        }
        #Profile{
            height:fit-content;
        }  
    }