#App{
    height:100%;
}
@media only screen and (max-width: 700px) {
#video{
    width:100% !important
}

#videoList{
    width:100% !important
}

}
#video > div > div.ui.segment > p{
    color:black;
}