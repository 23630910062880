.projectBody {
    margin-bottom: 100px;
}

.project {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
}

.projectTitle {
    margin-bottom: 20px;
    color: #000;
}

.projectContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.sliderContainer {
    position: relative;
    width: 100%;
    max-width: 400px;
    flex: 1;
}

.projectImage {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.sliderContainer:hover .overlay {
    opacity: 1;
}

.prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 24px;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    z-index: 2;
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}

.prev:hover, .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.btn {
    padding: 10px 20px;
    border: none;
    border-radius:
    4px;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btnPrimary {
    background-color: #007bff;
    color: #fff;
}

.btnPrimary:hover {
    background-color: #0056b3;
}

.projectContent {
    flex: 1;
    background-color: #fff;
    color: #000;
    padding: 20px;
    box-sizing: border-box;
    max-width: 400px;
}

.projectContentText {
    margin: 0;
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: medium;
    text-align: left;
}

@media screen and (max-width: 767px) {
    .projectContainer {
        flex-direction: column;
    }
}
