

.education-qualififcation{
    display:inline-block;
    margin-left:20px;
    width:55%;
    vertical-align: top;
}

.education-name{
    display:inline-block;
    width: 40%;
    vertical-align: top;
}

.education-section h3{
    color:white !important;
}

.experience-div{
   
  margin-left:15%;
  margin-right:15%;
  height:30%;

}

.education-section{
    margin-bottom:50px;
}